import { getLanguage } from "@project/common"
import dayjs from "dayjs"
import { useQuery } from "react-query"
import {
  GetAdultMonthlyAttendance,
  fetchAllProvisionalCity,
  fetchAllShiftMaster,
  fetchInfinityUsers,
  fetchInstructors,
  getAllMealMaster,
  getAllUserDailyRecord,
  getConfirmedBillingFacilities,
  getOccupations,
  getOneInstructor,
  getOneUser,
  getQualification,
  getUserDailyRecordRequest,
} from "../services"

import { fetchAllConstulationSupportOffice } from "../services/consultationSupportOffice"

export const useFetchAllDailyRecord = ({
  year,
  month,
}: {
  year: number
  month: number
}) => {
  const {
    data: dailyRecords,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["all-daily-record", year, month],
    queryFn: () => getAllUserDailyRecord({ year, month }),
    select: (res) => {
      return {
        ...res,
        data: res?.data.map((record) => {
          return {
            date: dayjs(record.date).format("YYYY-MM-DD"),
            remarks: record?.notes || "",
            key: record?.id,
            id: record?.id,
            physical_condition: record?.physical_condition || 0,
            work_detail_am: record?.work_detail_am,
            work_detail_pm: record?.work_detail_pm,
            work_impression: record?.work_impression,
            user: {
              id: record?.user?.id,
              username: record?.user?.name,
            },
          }
        }),
      }
    },
    refetchOnWindowFocus: false,
  })
  return {
    dailyRecords,
    isLoading,
    isFetching,
  }
}

export const useFetchAllDailyRecordRequest = ({
  year,
  month,
  page,
  pageSize,
  request_type = 1,
}: {
  year: number
  month: number
  page: number
  pageSize: number | "Infinity"
  request_type: number
}) => {
  const {
    data: dailyRecordsRequest,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["all-daily-record-request", year, month, page, request_type],
    queryFn: () =>
      getUserDailyRecordRequest({ year, month, pageSize, page, request_type }),
    refetchOnWindowFocus: false,
  })
  return {
    dailyRecordsRequest,
    isLoading,
    isFetching,
  }
}

export const useFetchOccupation = () => {
  const lng = getLanguage()
  const { data: occupations } = useQuery("occupations", getOccupations, {
    select: (response) => {
      return response?.data?.map((val) => ({
        label: lng === "en" ? val?.occupation_name_eng : val?.occupation_name,
        value: val?.id,
        type: val?.type,
      }))
    },

    refetchOnWindowFocus: false,
  })
  return { occupations }
}

export const useFetchQualifications = () => {
  const lng = getLanguage()
  const { data: qualifications } = useQuery({
    queryKey: ["qualifications"],
    queryFn: () => getQualification(),
    select: (response) => {
      return {
        ...response,
        data: response?.data?.map((val) => ({
          ...val?.data,
          label:
            lng === "en"
              ? val?.qualification_name_eng
              : val?.qualification_name,
          value: val?.id,
        })),
      }
    },

    refetchOnWindowFocus: false,
  })

  return { qualifications }
}

export const useFetchMealMaster = () => {
  const {
    data: mealData,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["meal-master"],
    queryFn: () => getAllMealMaster(),
    select: (response) => {
      return {
        ...response,
        data: response?.data?.map((val) => ({
          ...val,
          label: val?.name,
          value: val?.id,
        })),
      }
    },
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    retry: 1,
  })
  return { mealData, isFetching, isLoading }
}

export const useFetchConsultationSupportOffice = ({ page }) => {
  const {
    data: consultationSupportOffice,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["consultaion-support-office", page],
    queryFn: () => fetchAllConstulationSupportOffice({ page }),
    select: (response) => {
      return {
        data: response?.data?.map((val) => ({
          label: val?.consultation_support_name,
          value: val?.id,
        })),
      }
    },
    retry: 1,
  })
  return { consultationSupportOffice, isFetching, isLoading }
}

export const useFetchProvisionCity = ({ queryKey }) => {
  const {
    data: provisionCity,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["provision-city", queryKey],
    queryFn: () => fetchAllProvisionalCity(),
    select: (response) => {
      const data = [{ provision_city_name: "--", id: null }]
      const allData = [...data, ...response?.data]
      return {
        data: allData?.map((val) => ({
          label: val?.provision_city_name,
          value: val?.id,
        })),
      }
    },
    retry: 1,
  })
  return { provisionCity, isFetching, isLoading }
}

export const useFetchAllUsers = ({
  page,
  pageSize,
  setInitialValue,
}: {
  page: number
  pageSize: string
  setInitialValue?: boolean
}) => {
  const {
    data: userData,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["fetch-all-users", page],
    queryFn: () => fetchInfinityUsers({ page, pageSize }),
    refetchOnWindowFocus: false,
    select: (response) => {
      const data = setInitialValue
        ? [
            {
              value: null,
              label: "---",
              certificate: "",
              use_service_id: "",
              furigana: "",
              failure_types: "",
              start_date: "",
              assessment_count: 0,
            },
          ]
        : []
      response?.data?.map((val) => {
        data?.push({
          certificate: val?.user_certificate?.number || val?.account_number,
          use_service_id: val?.user_certificate?.service_type || "",
          furigana: val?.furigana,
          label: val?.username,
          assessment_count: val?.assessment_count || 0,
          value: `${val?.id}`,
          failure_types: val?.failure_types,
          start_date:
            val?.user_certificate?.start_date || val?.created_datetime,
        })
      })
      return data
    },
    retry: 1,
  })
  return { userData, isFetching, isLoading }
}

export const useFetchAllStaff = ({
  page,
  pageSize,
  enabled,
}: {
  page?: number
  pageSize: "Infinity"
  enabled?: boolean
}) => {
  const {
    data: staffData,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["all-staff"],
    queryFn: () => fetchInstructors({ page, pageSize }),
    select: (response) => {
      return response?.data?.map((val) => ({
        ...val,
        label: val?.staff_name,
        value: val?.id,
        staff_name_furiganaame: val?.staff_name_furiganaame || "",
      }))
    },
    retry: 1,
    enabled: enabled,
  })
  return { staffData, isFetching, isLoading }
}

export const useFetchOneStaff = (staff_id: any) => {
  const {
    data: staffDetail,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["all-staff"],
    queryFn: () => getOneInstructor(staff_id),
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: !!staff_id,
  })
  return { staffDetail, isFetching, isLoading }
}

export const useFetchAllShiftMaster = () => {
  const { data: shiftData, isLoading } = useQuery<any, Error>({
    queryKey: ["infinity-shift_master_list"],
    queryFn: () => fetchAllShiftMaster(),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    select: (response) => {
      const options = []
      response?.data?.map((v) => {
        options?.push({
          label: v?.attendance_shift_name,
          value: `${v?.id}`,
          attendance_end_time: v?.attendance_end_time,
          attendance_end_time2: v?.attendance_end_time_2,
          attendance_rest_minits: v?.attendance_rest_minits,
          attendance_rest_minits2: v?.attendance_rest_minits_2,
          attendance_start_time: v?.attendance_start_time,
          attendance_start_time2: v?.attendance_start_time_2,
          attendance_shift_facilities: v?.attendance_shift_facilities,
        })
      })
      return {
        data: response?.data,
        options,
      }
    },
  })
  return { shiftData, isLoading }
}

export const useFetchOneUser = (user_id: any) => {
  return useQuery({
    queryKey: ["one-user", user_id],
    queryFn: () => getOneUser(user_id),
    select: (res: any) => res || {},
    refetchOnWindowFocus: false,
  })
}

export const useFetchFacilitiesWithConfirmedBilling = (params: {
  year: number
  month: number
  facilityIds: any
}) => {
  const { isLoading, data: facilitiesWithConfirmedBilling } = useQuery({
    queryKey: ["get-facilities-with-confirmed-billing", params],
    queryFn: () =>
      getConfirmedBillingFacilities({
        year: params?.year,
        month: params?.month,
        facilityIds: params?.facilityIds,
      }),
    enabled: !!params?.facilityIds,
  })
  return { facilitiesWithConfirmedBilling, isLoading }
}

export const useFetchAllAttendance = ({
  year,
  month,
  panel,
}: {
  year: number
  month: number
  panel?: string
}) => {
  const {
    data: allAttendanceRecord,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["all-attendance-record-my-month", year, month, panel],
    queryFn: () => GetAdultMonthlyAttendance({ year, month }),
    refetchOnWindowFocus: false,
  })
  return {
    allAttendanceRecord,
    isLoading,
    isFetching,
  }
}
