import { API } from "@project/common"

export const getUserMonthFacilitySchedule = () => {
  return API.get(`/adult-user/me`)
}
export const createOrUpdateUserFacilitySchedules = (values: any) => {
  return API.post(`/adult-user/me`, values)
}

export const fetchUserMonthFacilitySchedule = ({
  user_id,
  year,
  month,
}: {
  user_id: string | number
  year: string | number
  month: string | number
}) => {
  return API.get(
    `/adult-user/user-facility-schedules/monthly/${user_id}/${year}/${month}`,
  )
}
